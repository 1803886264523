var columns = [{
  title: '售后单编号',
  dataIndex: 'aftersaleno',
  key: 'aftersaleno',
  scopedSlots: {
    customRender: 'aftersaleno'
  }
}, {
  title: '售后原因',
  dataIndex: 'closingreason',
  key: 'closingreason',
  scopedSlots: {
    customRender: 'closingreason'
  }
}, {
  title: '订单号',
  dataIndex: 'orderno',
  key: 'orderno',
  scopedSlots: {
    customRender: 'orderno'
  }
}, {
  title: '商家',
  dataIndex: 'sellereamil',
  key: 'sellereamil',
  width: '8%',
  scopedSlots: {
    customRender: 'sellereamil'
  },
  slots: {
    title: 'emailTit'
  }
}, {
  title: '商家类型',
  dataIndex: 'sellertype',
  key: 'sellertype',
  width: '6%',
  scopedSlots: {
    customRender: 'sellertype'
  }
}, {
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品名称',
  dataIndex: 'productName',
  key: 'productName',
  width: '12%',
  scopedSlots: {
    customRender: 'productName'
  }
}, {
  title: '订单总额($)',
  dataIndex: 'amount',
  key: 'amount',
  width: "8%",
  scopedSlots: {
    customRender: 'amount'
  }
}, {
  title: '商品图片',
  dataIndex: 'imgurls',
  key: 'imgurls',
  width: "6%",
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '创建时间',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '12%',
  scopedSlots: {
    customRender: 'createtime'
  }
},
// {
//     title: '订单来源',
//     dataIndex: 'source',
//     key: 'source',
//     scopedSlots: {
//         customRender: 'source'
//     }
// },
{
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };